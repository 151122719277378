import React from 'react';
import { Container, Grid, Box, Typography } from '@mui/material';
import place1 from '../../Assets/Images/mhalaxmitemple.jpg';
import place2 from '../../Assets/Images/jotibatemple.jpg';
import place3 from '../../Assets/Images/panhalafort.jpg';
import place4 from '../../Assets/Images/newpalace.gif';
import place5 from '../../Assets/Images/rankalalake.jpg';
import place6 from '../../Assets/Images/kanerimath.jpg';
import place7 from '../../Assets/Images/cinmayganapati.jpg';
import place8 from '../../Assets/Images/narasobawadi.jpg';
import place9 from '../../Assets/Images/audumbar.jpg';
import place10 from '../../Assets/Images/sangliganapati.jpg';
import bookroomanimation from '../../Assets/Images/bookroomanimation.png';

const KolhapurTourismSection = () => {
  const tourismPlaces = [
    {
      id: 1,
      image: place1,
      name: 'Mahalakshmi Temple',
      description: 'Kolhapur famous as a divine destination – residence of Shri Mahalaxmi (Ambabai). One among the ‘Shaktipeethas’, Kolhapur attracts the pilgrims of the world. They visit Kolhapur',
      
    },
    {
      id: 2,
      image: place2,
      name: 'Jotiba Temple',
      description: 'People from all, over the world visit Jotiba. The large numbers of devotees of Jotiba are spread among the states of Karnataka, Tamilnadu, Andhra Pradesh, Gujarat and Madhya Pradesh',
    //   link: '/rankala-lake',
    },
    {
      id: 3,
      image: place3,
      name: 'Panhala Fort',
      description: 'Panhala fort (Marathi: पन्हाळा), also known as Panhalgad, Pahalla and Panalla (literally "the home of serpents"), is located in Panhala, 20 kilometres northwest of Kolhapur in Maharashtra.The main historical attraction here is the Panhala fort',
    //   link: '/panhala-fort',
    },
    {
      id: 4,
      image: place4,
      name: 'New Palace',
      description: 'Home to the living descendants of Chatrapati Shahu Maharaj. The Ground Floor has been converted into a Museum. The Museum displays furniture, weapons, clothing used during the regime and by His Highness Shri Chhatrapati Shahu Maharaj himself...',
    //   link: '/jyotiba-temple',
    },
    {
      id: 5,
      image: place5,
      name: 'Rankala Lake',
      description: 'Rankala Lake is a picturesque lake in the Kolhapur District of the state of Maharashtra in western India. It is believed to be linked directly to a well in Panhala Fort which Shivaji Maharaj is ',
    //   link: '/shahu-maharaj-museum',
    },
    {
      id: 6,
      image: place6,
      name: 'kaneri Math',
      description: 'Siddhagiri Gramjivan Wax Museum (Kaneri Math) at Kaneri near Kolhapur, Maharashtra is a unique wax museum, probably the only one of its kind in India. The museum is called ‘Siddhagiri Gramjivan (Village life) Museum’.',
    //   link: '/amba-ghat',
    },
    {
      id: 7,
      image: place7,
      name: 'Chinmaya Ganpati',
      description: 'The 66-feet Chinmaya Ganpati idol in Kolhapur is at present considered to be the tallest Ganesha idol in the world. The idol sits on 24-feet Dhyana Nilayam (raised platform). Thus the statue is 90 feet from the ground level.',
    //   link: '/mangalwedha-ganpati',
    },
    {
      id: 8,
      image: place8,
      name: 'Narsobachi wadi',
      description: 'Sri Narasimha Saraswati visited the Pancha Ganga Sagar, which is the confluence of the five holy rivers, Siva, Bhadra, Kumbhi, Bhagwati and Saraswati, where they all meet and merge in the ...',
    //   link: '/shri-kshetra-panhala',
    },
    {
      id: 9,
      image: place9,
      name: 'Audumbar',
      description: 'Audumbar datta temple is a holy place about 25 km. from Sangli city. A sacred temple of Lord Dattatraya in audumbar is located on the banks of Holy Krishna river. Thousands of devotees from all over the world visit Audumbar datta temple every year.',
    },
    {
      id: 10,
      image: place10,
      name: 'Sangali Ganpati',
      description: 'Ganapati Temple is regarded as the most beautiful temple in south Maharashtra. It is situated on the banks of river Krishna at Sangli. Thorle (elder) Chintamanrao Patwardhan consecrated the idol in 1843.',
    //   link: '/sahyadri-hills',
    },
  ];

  return (
    <Box id="tourism" sx={{ position: 'relative', paddingTop: '90px', paddingBottom: '90px' }}>
      <Box sx={{ position: 'absolute', top: '80px', right: 0 }}>
        <img src={bookroomanimation} alt="contact-bg-an-05" />
      </Box>
      <Container maxWidth="lg" sx={{ paddingLeft: '16px', paddingRight: '16px' }}>
        <Box textAlign="center" marginBottom="50px">
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              color: '#644222',
              fontSize: '18px',
              marginBottom: '10px',
              textTransform: 'capitalize',
              fontFamily: 'Jost, sans-serif',
              fontStyle: 'normal',
              fontWeight: 600,
              marginTop: 0,
            }}
          >
            Kolhapur Tourism
          </Typography>

          <Typography
            variant="h2"
            gutterBottom
            sx={{
              fontSize: '60px',
              marginBottom: '0 !important',
              paddingBottom: '0',
              position: 'relative',
              color: '#101010',
              fontFamily: 'Jost, sans-serif',
              fontStyle: 'normal',
              fontWeight: 600,
              marginTop: 0,
            }}
          >
            Explore Kolhapur
          </Typography>

          <Typography
            sx={{
              display: 'inline-block',
              marginTop: '10px',
              width: '60%',
            }}
          >
            Discover the rich heritage, natural beauty, and cultural gems of Kolhapur. This city offers a variety of tourist spots, from ancient temples to serene lakes and majestic forts.
          </Typography>
        </Box>

        <Grid container spacing={6}>
          {tourismPlaces.map((place) => (
            <Grid item xs={12} sm={6} md={4} key={place.id}>
              <Box
                sx={{
                  boxShadow: 3,
                  overflow: 'hidden',
                  width: '100%',
                  margin: '0 auto',
                  position: 'relative', // Required for the absolute positioning of name
                }}
              >
                <Box
                  component="a"
                  href={place.link}
                  sx={{
                    display: 'block',
                    position: 'relative', // Ensure the name is positioned relative to this box
                    '& img': {
                      transition: 'transform 0.3s ease-in-out',
                    },
                    '&:hover img': {
                      transform: 'scale(1.1)',
                    },
                  }}
                >
                  <img
                    src={place.image}
                    alt={place.name}
                    style={{
                      width: '100%',
                      display: 'block',
                    }}
                  />
                  {/* Name wrapper */}
                  <Box
                    sx={{
                      background: '#644222',
                      color: '#fff',
                      left: '25%', // Position it in the middle
                      transform: 'translateX(-50%)', // Center it horizontally
                      padding: '5px 12px',
                      position: 'absolute',
                      top: '90%', // Place it in the middle vertically
                      zIndex: 1.2, // Ensure it stays on top of the image
                    }}
                  >
                    <Typography variant="subtitle2" color="inherit" gutterBottom>
                      {place.name}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ padding: '35px', minHeight: '250px' }}> {/* Added minHeight to increase the height */}
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{
                      fontFamily: 'Jost, sans-serif',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      fontSize: '22px',
                      lineHeight: '30px',
                      textDecoration: 'none',
                      color: '#101010',
                      marginBottom: '16px', // Added space below title
                    }}
                  >
                    <Box component="a" href={place.link} color="inherit" sx={{ textDecoration: 'none' }}>
                      {place.name}
                    </Box>
                  </Typography>
                  <Typography variant="body2" gutterBottom sx={{ marginBottom: '16px' }}>
                    {place.description}
                  </Typography>
                  {/* <Typography
                    component="a"
                    href={place.link}
                    sx={{
                      color: '#644222',  // Set the text color to #644222
                      textDecoration: 'none',  // Remove the underline to apply custom styles
                      cursor: 'pointer',  // Make it a pointer on hover
                      borderBottom: '3px solid #644222',  // Apply border bottom
                      display: 'inline-block',  // Make it behave like an inline block
                      fontSize: '15px',  // Set font size
                      fontWeight: 500,  // Set font weight
                      textAlign: 'center',  // Center the text
                      textTransform: 'uppercase',  // Uppercase text
                      paddingBottom: '4px',  // Add some padding to keep the link content spaced properly
                      transition: 'color 0.3s ease',  // Optional: Add transition for hover effect
                      '&:hover': {
                        color: 'primary.main',  // Optional: Change color on hover
                      },
                    }}
                  >
                    Read More
                  </Typography> */}
                </Box>

              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default KolhapurTourismSection;
