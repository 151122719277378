import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Container,
  Typography,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
// import HomeSlider from "../../Pages/HomeSlider";
import { Link } from "react-router-dom";

const MainHeader = () => {
  const [menuAnchor, setMenuAnchor] = useState(null);
  // const [isAboutPage, setIsAboutPage] = useState(false);

  // useEffect(() => {
  //   // Check if the current path is the "About" page
  //   if (window.location.pathname === "/About") {
  //     setIsAboutPage(true);
  //   } else {
  //     setIsAboutPage(false);
  //   }
  // }, []);

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  return (
    <div style={{ position: "relative", fontFamily: "Jost, sans-serif" }}>
      <AppBar
        position="absolute"
        sx={{
          backgroundColor: "#000",
          boxShadow: "none",
          zIndex: 20, // Ensure header is above the slider
          fontFamily: "Jost, sans-serif",
        }}
      >
        <Container>
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
              <img
                src={require("../../Assets/Images/row-bg.png")}
                alt="logo"
                style={{ maxHeight: "50px" }}
              />
            </Box>

            <Box
              sx={{
                flexGrow: 8,
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="body1" component="div" sx={{ mx: 2 }}>
                <Link
                  to="/"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Home
                </Link>
              </Typography>
              <Typography variant="body1" component="div" sx={{ mx: 2 }}>
                <Link
                  to="/about"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  About
                </Link>
              </Typography>
              <Typography variant="body1" component="div" sx={{ mx: 2 }}>
                <Link
                  to="/room"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Our Rooms
                </Link>
              </Typography>
              <Typography variant="body1" component="div" sx={{ mx: 2 }}>
                <Link
                  to="/facilities"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Facilities
                </Link>
              </Typography>
              <Typography variant="body1" component="div" sx={{ mx: 2 }}>
                <Link
                  to="/gallery"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Gallery
                </Link>
              </Typography>
              {/* <Typography variant="body1" component="div" sx={{ mx: 2 }}>
                <Link
                  to="/blog"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Blog
                </Link>
              </Typography> */}
              <Typography variant="body1" component="div" sx={{ mx: 2 }}>
                <Link
                  to="/kolhapurtourism"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Kolhapur Tourism
                </Link>
              </Typography>
              <Typography variant="body1" component="div" sx={{ mx: 2 }}>
                <Link
                  to="/contact"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Contact
                </Link>
              </Typography>
              {/* <Typography variant="body1" component="div" sx={{ mx: 2 }}>
                <Link
                  to="/userlist"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  UserList
                </Link>
              </Typography> */}
            </Box>

            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#be9874",
                  "&:hover": { backgroundColor: "#be9874" },
                  ml: 2,
                }}
                href="/contact"
              >
                Reservation
              </Button>
            </Box>

            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <Button
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenuOpen}
                sx={{
                  color: "white",
                  border: "1px solid #fff",
                }}
              >
                <MenuIcon />
              </Button>

              <Menu
                id="menu-appbar"
                anchorEl={menuAnchor}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(menuAnchor)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleMenuClose}>
                  <Link
                    to="/"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Home
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleMenuClose}>
                  <Link
                    to="/about"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    About
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleMenuClose}>
                  <Link
                    to="/room"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Our Rooms
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleMenuClose}>
                  <Link
                    to="/service"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Facilities
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleMenuClose}>
                  <Link
                    to="/contact"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Contact
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleMenuClose}>
  <Button
    variant="contained" // Explicitly set the variant
    sx={{
      backgroundColor: "#820000",
      "&:hover": { backgroundColor: "#5a0000" }, // Ensure hover color is distinct
    }}
    to="/contact"
    fullWidth
  >
    Reservation
  </Button>
</MenuItem>

              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {/* Conditionally render the HomeSlider only if it's not the About page */}
      {/* {!isAboutPage && <HomeSlider />} */}
    </div>
  );
};

export default MainHeader;
