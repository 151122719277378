import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import mapimage from "../../Assets/Images/map.jpg";  // Importing image

// ImageDisplay Component
const ImageDisplay = ({ src, width = "100%", height = "auto" }) => {
  return (
    <Container
    sx={{
        py: 10,
        backgroundcolor: '#f7f5f1',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Box
        sx={{
          display: 'flex',  // Enables Flexbox
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          textAlign: 'center',
          position: 'relative',
        }}
      >
       <Typography
                       variant="h2"
                       align="center"
                       gutterBottom
                       sx={{
                         color: '#101010',
                         fontFamily: 'Jost, sans-serif',
                         fontStyle: 'normal',
                         fontWeight: 600,
                         marginTop: 0,
                         textTransform: 'normal',
                         marginBottom: '15px',
                       }}
                     >
                  Reach Out To Us
                     </Typography>

        <Typography 
          variant="h6" 
          gutterBottom 
          sx={{
            color: '#101010',  // Subheading color
            fontWeight: 500,
            marginBottom: '20px',
            fontFamily: 'Jost, sans-serif',
          }}
        >
          This is the map of our kedarling Yatri Nivas location
        </Typography>
        
        <img 
          src={src} 
          alt="display-image" 
          style={{
            width: '100%',
            height: 'auto',  // Ensures responsive height
            objectFit: 'cover',  // Ensures image covers the entire area
            display: 'block',
            marginBottom: '20px',
            borderRadius: '8px',  // Rounded corners
            boxSizing: 'border-box',  // Ensures padding and border won't increase image size
          }} 
        />
      </Box>
    </Container>
  );
};

export default ImageDisplay;
