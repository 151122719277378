import React from 'react';
import { Grid, Button } from '@mui/material';
import luxuryhotel from '../../Assets/Images/luxuryhotel.png';
import dataimg from '../../Assets/Images/dataimg.png';

const LuxuryHotel = () => {
  return (
    <section
      style={{
        position: 'relative',
        backgroundImage: `url(${dataimg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: '90vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '110px',
      }}
    >
      <div className="container">
        <Grid container justifyContent="center" alignItems="center" spacing={6}>
          {/* Left Side: Image */}
          <Grid item xs={12} md={6} lg={6}>
            <div className="feature-img">
              <img
                src={luxuryhotel}
                alt="Luxury Hotel"
                style={{
                  width: '100%',
                  height: '90vh',
                  objectFit: 'cover', // Ensures the image fills the container proportionally
                }}
              />
            </div>
          </Grid>

          {/* Right Side: Content */}
          <Grid item xs={12} md={6} lg={6}>
            <div>
              <div style={{ paddingBottom: '20px', paddingLeft:'10px' }}>
                <h5 style={{ color: '#101010', fontSize: '18px' }}>Luxury Hotel &amp; Resort</h5>
                <h2 style={{ color: '#101010', fontSize: '72px', margin: '10px 0', lineHeight: '1.2' }}>
                  Pearl Of The<br /> Adriatic.
                </h2>
              </div>
              <p style={{ marginBottom: '15px', paddingLeft:'10px'}}>
                Vestibulum non ornare nunc. Maecenas a metus in est iaculis pretium. Aliquam ullamcorper
                nibh lacus, ac suscipit ipsum consequat porttitor. Aenean vehicula ligula eu rhoncus porttitor.
                Duis vel lacinia quam. Nunc rutrum porta ex, in imperdiet tortor feugiat at.
              </p>
              <p style={{ marginBottom: '20px',paddingLeft:'10px' }}>
                Cras finibus laoreet felis et hendrerit. Integer ligula lorem, finibus vitae lorem at, egestas
                consectetur urna. Integer id ultricies elit. Maecenas sodales nibh, quis posuere felis. In commodo
                mi lectus venenatis metus eget fringilla. Suspendisse varius ante eget.
              </p>
              <Button
                variant="contained"
                href="/about"
                style={{
                  backgroundColor: '#644222',
                  color: '#fff',
                  padding: '10px 20px',
                  fontSize: '16px',
                  textTransform: 'none',
                  borderRadius: 0, // Remove button border-radius
                  marginLeft:'10px'
                }}
              >
                Discover More
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default LuxuryHotel;
