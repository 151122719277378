import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import About from "./Pages/AboutUs/About";
import Facilities from "./Pages/Facilities/Facilities";
import Blog from "./Pages/Blog/Blog";
import Contact from "./Pages/Contact/Contact";
import OurRooms from "./Pages/Room/OurRooms";
import Booking from "./Pages/Home/Booking";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Gallery from "./Pages/Gallery/Gallery";
// import UserList from "./Pages/UserList";
import KolhapurTourism from "./Pages/KolhapurTourism/KolhapurTourism";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} exact /> {/* Home Page */}
          <Route path="/booking" element={<Booking />} /> 
          <Route path="/about" element={<About />} /> {/* About Page */}
          <Route path="/facilities" element={<Facilities />} /> {/* Facilities Page */}
          {/* <Route path="/blog" element={<Blog />} /> Blog Page */}
          <Route path="/kolhapurtourism" element={<KolhapurTourism />} /> {/* Contact Page */}

          <Route path="/room" element={<OurRooms />} /> {/* Blog Page */}
          <Route path="/gallery" element={<Gallery/>} /> {/* Blog Page */}
          <Route path="/contact" element={<Contact />} /> {/* Contact Page */}
          {/* <Route path="/userlist" element={<UserList />} /> Contact Page */}
        {/* <Route path/> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
