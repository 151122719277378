import React, { useState } from 'react';
import { Container, Typography, Box, Grid, Card, CardMedia, CardContent, Button } from '@mui/material';
import jina from '../../Assets/Images/kushappa_khade_1.jpg';
import braitly from '../../Assets/Images/rdk.jpg';
import robot from '../../Assets/Images/satyajeet.jpg';
import testimonial from '../../Assets/Images/testimonial.png';

const teamMembers = [
  {
    name: 'Kushappa Khade',
    title: 'Owner',
    image: jina,
    description:
      'The credit of our success goes to Late H. B. P. Dattoba Kushappa Khade who established Kedarling Bakery way back in 1955. With a home-based production unit & a sales counter, he started this business. His restless efforts took this business to the summit within a short time.',
    fullDescription:
      'He added more values in this business with quality consciousness. During a very short period, he set this business very high in and around the region. People appreciated the products and their quality. To deliver the best quality is still our motto. Perhaps this is the only secret of our success even in today\'s era.',
  },
  {
    name: 'Rajaram Khade',
    title: 'Owner',
    image: braitly,
    description:
      'Today; Mr. Rajaram Dattoba Khade, Mr. Satyajeet Rajaram Khade are looking after the expansion. Mr. Sushant Rajaram Khade is looking after management, production & quality control. Mr. Rajaram Khade\'s vision is the backbone of the overall development of Kedarling Bakery.',
    fullDescription:
      'Mr. Rajaram Khade\'s vision is the backbone of the overall development of Kedarling Bakery. Considering his social work, Mr. Rajaram Khade has been awarded the "Udyog Ratna" award. The whole business is developing under his guidance. His dynamic vision has gifted him a vast friend circle in & around Kolhapur.',
  },
  {
    name: 'Satyajeet Khade',
    title: 'Owner',
    image: robot,
    description:
      'Mr. Satyajeet Rajaram Khade alias Bhau Khade is popularly known among his friend circle. Working very hard for 15 years, Satyajeet has set himself apart in this industry. Regular updates of technology have contributed to the bakery’s growth.',
    fullDescription:
      'Working very hard for 15 years, Satyajeet has set himself apart in this industry. Regular updates of technology and being in touch with senior members of the Bakery Association of India have earned him a place in the Western India Bakery Association. His efforts have significantly contributed to the bakery’s continuous progress.',
  },
];

const TeamMemberCard = ({ name, title, image, description, fullDescription }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <Card
      sx={{
        // backgroundImage: `url(${testimonial})`,
        minHeight: showMore ? 'auto' : '450px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        borderRadius: '8px',
        overflow: 'hidden',
        transition: 'transform 0.3s',
        '&:hover': { transform: 'scale(1.05)', boxShadow: '0 6px 15px rgba(0, 0, 0, 0.2)' },
      }}
    >
      <CardMedia
        component="img"
        alt={name}
        image={image}
        sx={{
          height: '350px',
          objectFit: 'cover',
          borderBottom: '4px solid #644222',
        }}
      />
      <CardContent sx={{ padding: '20px' }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#644222', mb: 1 }}>
          {name}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: '#888', mb: 2 }}>
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: '#555',
            textAlign: 'justify',
            lineHeight: '1.6',
            mb: 2,
            maxHeight: showMore ? 'none' : '100px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {showMore ? `${description} ${fullDescription}` : description}
        </Typography>
        <Button
          onClick={() => setShowMore(!showMore)}
          sx={{
            textTransform: 'none',
            fontWeight: 'bold',
            color: '#644222',
          }}
        >
          {showMore ? 'Read Less' : 'Read More'}
        </Button>
      </CardContent>
    </Card>
  );
};

const OurTeam = () => (
  <Box
    sx={{
      position: 'relative',
      py: 10,
      backgroundColor: '#f9f9f9',
      backgroundImage: `url(${testimonial})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}
  >
    <Container>
    <Typography
        variant="h5"
        align="center"
        gutterBottom
        sx={{
          color: '#644222',
          fontSize: '18px',
          marginBottom: '10px',
          textTransform: 'capitalize',
          fontFamily: 'Jost, sans-serif',
          fontWeight: 600,
        }}
      >
        Our Team
      </Typography>

      <Typography
        variant="h2"
        align="center"
        gutterBottom
        sx={{
          color: '#101010',
          fontFamily: 'Jost, sans-serif',
          fontWeight: 600,
          marginBottom: '15px',
        }}
      >
        Meet the Team
      </Typography>

      <Typography
        variant="body1"
        align="center"
        paragraph
        sx={{ mb: 6, maxWidth: '600px', mx: 'auto', color: '#555' }}
      >
        Our dedicated team members work tirelessly to deliver exceptional results and create
        remarkable experiences for our clients and partners.
      </Typography>
      <Grid container spacing={4}>
        {teamMembers.map((member, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <TeamMemberCard {...member} />
          </Grid>
        ))}
      </Grid>
    </Container>
  </Box>
);

export default OurTeam;
