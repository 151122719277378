import React from 'react'
import PageLayout from '../../Components/Layout/PageLayout'

import BreadcrumbSectionKolhapurTourism from './BreadcrumbSectionKolhapurTourism'
import KolhapurTourismSection from './KolhapurTourismSection'

const KolhapurTourism = () => {
  return (
    <div>
      <PageLayout>
        <BreadcrumbSectionKolhapurTourism/>
       {/* <RoomCard/>
       <LuxuryHotel/>
       <BookRoom/>
       <TakeTour/> */}
    <KolhapurTourismSection/>
      </PageLayout>
    </div>
  )
}

export default KolhapurTourism;
