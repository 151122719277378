import React from "react";
import { Container, Grid, Typography, Box } from "@mui/material";

const InfoSection = () => {
  return (
    <Container 
      maxWidth="xl" 
      sx={{ backgroundColor: "#f1f1f1", padding: "30px", boxShadow: 3 }}
    >
      <Grid container spacing={2}>
        {/* Kolhapur City */}
        <Grid item xs={12} md={4}>
          <Box>
            <Typography 
              variant="h4" 
              color="orangered" 
              sx={{ fontFamily: "Times New Roman", fontStyle: "italic" }}
            >
              Kolhapur City
            </Typography>
            <Typography 
              variant="body1" 
              color="black" 
              sx={{ fontFamily: "Times New Roman", fontStyle: "italic" }}
            >
              Kolhapur city is a great historical place.
              <br /> Many tourists visit Kolhapur City.
              <br /> Let&apos;s check out what you can see ...
            </Typography>
          </Box>
        </Grid>

        {/* Room Services */}
        <Grid item xs={12} md={4}>
          <Box>
            <Typography 
              variant="h4" 
              color="orangered" 
              sx={{ fontFamily: "Times New Roman", fontStyle: "italic" }}
            >
              Room Services
            </Typography>
            <Typography 
              variant="body1" 
              color="black" 
              sx={{ fontFamily: "Times New Roman", fontStyle: "italic" }}
            >
              We are providing not only just
              <br /> common services, but ...
            </Typography>
          </Box>
        </Grid>

        {/* Kedarling Bakery */}
        <Grid item xs={12} md={4}>
          <Box>
            <Typography 
              variant="h4" 
              color="orangered" 
              sx={{ fontFamily: "Times New Roman", fontStyle: "italic" }}
            >
              Kedarling Bakery
            </Typography>
            <Typography 
              variant="body1" 
              color="black" 
              sx={{ fontFamily: "Times New Roman", fontStyle: "italic" }}
            >
              From 1955 we are providing the best bakery products.
              <br /> Visit <a href="https://www.kedarlingbakery.com">www.kedarlingbakery.com</a>.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default InfoSection;
