import React from 'react';
import { Box, Container, Grid, Typography, Button, TextField, } from '@mui/material';
import { Facebook, Twitter, Instagram } from '@mui/icons-material';
import footerimg  from "../../Assets/Images/footerimg.png"
import logofooter  from "../../Assets/Images/row-bg.png"
import { Link } from 'react-router-dom';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';


const Footer = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${footerimg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        paddingTop: '80px',
        // paddingBottom: '90px',
        // height:'60vh'
      }}
    >
      <Container >
      <Grid container justifyContent="space-between" sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
          {/* Left Column */}
          <Grid item xl={4} lg={4} sm={6}>
         

<Box
  sx={{
    width: '146px', // Fixed width for the container
    // display: 'flex', // Ensures proper alignment for the logo
     justifyContent: 'center', // Centers the image horizontally
    alignItems: 'center', // Centers the image vertically
  }}
>
  <img
    src={logofooter}
    alt="Logo"
    style={{
    maxWidth: '200%', // Ensures the image does not exceed the container width
      color: '#fff',
      fontSize: '24px',
      marginBottom: '5px',
      paddingBottom: '30px',
      position: 'relative',
    //   height: 'auto', // Maintains aspect ratio
    }}
  />
              <Box>
  <Box
    component="ul"
    sx={{
        color: '#fffc',
        display: 'inline-block',
        padding: 0, // Reset padding for `ul`
        margin: 0, // Reset margin for `ul`
        listStyleType: 'none', // Removes default bullet points
    }}
  >
    {/* Phone */}
    <Box
      component="li"
      sx={{ marginBottom: '15px', color: '#fff' }} // Optional spacing and color
    >
      <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
      <CallIcon sx={{ marginRight: '10px'}}></CallIcon>
      0231-2626777<br />  9890955077
      </Typography>
    </Box>

    {/* Email */}
    <Box
      component="li"
      sx={{ marginBottom: '15px', color: '#fff' }}
    >
      <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
      <EmailIcon sx={{ marginRight: '10px'}}></EmailIcon> 
        <Link
          to="mailto:info@example.com"
          style={{ color: '#fff', textDecoration: 'none' }}
        >
         info@kedarlingyatrinivas.com
        </Link>
        
        <Link
          to="mailto:help@example.com"
          style={{ color: '#fff', textDecoration: 'none' }}
        >
          
        </Link>
      </Typography>
    </Box>

    {/* Address */}
    <Box
      component="li"
      sx={{ marginBottom: '15px', color: '#fff' }}
    >
      <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
      <LocationOnIcon sx={{ marginRight: '10px'}}></LocationOnIcon> 

      Kedarling Yatri Nivas,
Mahalxmi Temple, Tarabai Road,
Opp. Babujamal Darga, Kolhapur.
      </Typography>
    </Box>
  </Box>
</Box>
            </Box>
          </Grid>

          {/* Middle Column */}
          <Grid item xl={2} lg={2} sm={6}>
      

<Box>
  <Typography
    variant="h6"
    sx={{
      color: '#fff',
      fontSize: '24px',
      marginBottom: '5px',
      paddingBottom: '30px',
      position: 'relative',
    }}
  >
    Our Links
  </Typography>
  <Box
    component="ul"
    sx={{
      color: '#fffc',
      display: 'inline-block',
      padding: 0, // Reset padding for `ul`
      margin: 0, // Reset margin for `ul`
      listStyleType: 'none', // Removes default bullet points
    }}
  >
    <Box
      component="li"
      sx={{ marginBottom: '10px' }} // Optional spacing between links
    >
      <Link
        to="/"
        style={{
          color: '#fffc',
          textDecoration: 'none', // Removes underline
        }}
      >
        Home
      </Link>
    </Box>
    <Box
      component="li"
      sx={{ marginBottom: '10px' }}
    >
      <Link
        to="/about"
        style={{
          color: '#fffc',
          textDecoration: 'none',
        }}
      >
        About Us
      </Link>
    </Box>
    <Box
      component="li"
      sx={{ marginBottom: '10px' }}
    >
      <Link
        to="/facilities"
        style={{
          color: '#fffc',
          textDecoration: 'none',
        }}
      >
        Services
      </Link>
    </Box>
    <Box
      component="li"
      sx={{ marginBottom: '10px' }}
    >
      <Link
        to="/contact"
        style={{
          color: '#fffc',
          textDecoration: 'none',
        }}
      >
        Contact Us
      </Link>
    </Box>
    <Box
      component="li"
      sx={{ marginBottom: '10px' }}
    >
      <Link
        to="/kolhapurtourism"
        style={{
          color: '#fffc',
          textDecoration: 'none',
        }}
      >
        Kolhapur Tourism
      </Link>
    </Box>
  </Box>
</Box>

          </Grid>

          {/* Right Column */}
          <Grid item xl={2} lg={2} sm={6}>
          <Box>
  <Typography
    variant="h6"
    sx={{
      color: '#fff',
      fontSize: '24px',
      marginBottom: '5px',
      paddingBottom: '30px',
      position: 'relative',
    }}
  >
    Our Services
  </Typography>
  <Box
    component="ul"
    sx={{
      color: '#fffc',
      display: 'inline-block',
      padding: 0, // Reset padding for `ul`
      margin: 0, // Reset margin for `ul`
      listStyleType: 'none', // Removes default bullet points
    }}
  >
    <Box
      component="li"
      sx={{ marginBottom: '10px' }} // Optional spacing between links
    >
      <Link
        to=""
        style={{
          color: '#fffc',
          textDecoration: 'none', // Removes underline
        }}
      >
        FAQ
      </Link>
    </Box>
    <Box
      component="li"
      sx={{ marginBottom: '10px' }}
    >
      <Link
        to="/room"
        style={{
          color: '#fffc',
          textDecoration: 'none',
        }}
      >
        Support
      </Link>
    </Box>
    <Box
      component="li"
      sx={{ marginBottom: '10px' }}
    >
      <Link
        to="/room"
        style={{
          color: '#fffc',
          textDecoration: 'none',
        }}
      >
        Privacy
      </Link>
    </Box>
    <Box
      component="li"
      sx={{ marginBottom: '10px' }}
    >
      <Link
        to="/room"
        style={{
          color: '#fffc',
          textDecoration: 'none',
        }}
      >
        Terms & Conditions
      </Link>
    </Box>
  </Box>
</Box>
          </Grid>

          {/* Subscription Column */}
          <Grid item xl={4} lg={4} sm={6}>
            <Box>
              <Typography variant="h6" sx={{
    color: '#fff',
    fontSize: '24px',
    marginBottom: '5px',
    paddingBottom: '30px',
    position: 'relative',
  }}>Subscribe To Our Newsletter</Typography>
              <Typography variant="body2"sx={{
   color: '#fffc',
    fontSize: '18px',
    marginBottom: '5px',
    paddingBottom: '30px',
    position: 'relative',
  }}>Subscribe to get our latest updates & news</Typography>
           <Box
  className="subscribe"

>
  <form
    action="news-mail.php"
    method="post"
    className="contact-form"
    
  >
    {/* Email Input */}
    <TextField
      id="email2"
      name="email2"
      placeholder="Your Email..."
      variant="outlined"
      required
      fullWidth
      // height='100%'
      sx={{
        borderRadius: '0', // Match the structure
        // paddingRight: '60px', // Space for the button (adjusted for the button size)
      // Width:'100%',
    
        backgroundColor: '#fff',
        '& .MuiOutlinedInput-root': {
          borderRadius: '0',
          height:'4.4rem', // Remove rounded corners from the input
          background:" #f8f8f8",
          border: "1px solid #f8f8f8",
          // borderRadius: "5px",
          // borderadius: 0,
          // height: 4.4rem;
          marginBottom: 0,
          outline:" none !important",
          padding:" 0 1rem",
        },
      }}
      InputProps={{
        endAdornment: (
          <i className="fas fa-location-arrow" style={{ background: '#644222',
            border: 'none',
            borderRadius: 0,
            color: '#fff',
            padding: '8px 16px', // Adjust padding to make the button size more appropriate
            marginLeft: '10px', // Add some space between the button and input
            height: '80%', // Ensure the button takes the full height of the input field
            minWidth: 'auto', // Prevent the button from stretching too wide
            boxSizing: 'border-box', // Ensure padding doesn't affect the width/height
            display: 'flex', // Make the button a flex container
            alignItems: 'center', // Vertically center the icon
            justifyContent: 'center', 
         }} />
        ),
      }}
    />

    {/* Submit Button */}
    
  </form>
</Box>

            </Box>
          </Grid>
        </Grid>

        {/* Copyright and Social Links */}
       



      </Container>
      <Box
  sx={{
    background: '#282828',
    color: '#fff',
    fontSize: '15px',
    padding: '20px 10px',
    textAlign: 'center',
    //  marginbottom: '-45px',  // Added marginTop to create space between sections
  }}
>
  <Container maxWidth="lg">
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={12} sm={6}>
        <Typography variant="body2" sx={{ textAlign: 'left' }}>
        Kedarling Yatri Nivas, Kolhapur © 2011-2021 All Rights Reaserved.
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button to="/" sx={{ display: 'flex', alignItems: 'center' }}>
            <Facebook sx={{ color: '#fff' }} />
          </Button>
          <Button to="/" sx={{ display: 'flex', alignItems: 'center' }}>
            <Twitter sx={{ color: '#fff' }} />
          </Button>
          <Button to="/" sx={{ display: 'flex', alignItems: 'center' }}>
            <Instagram sx={{ color: '#fff' }} />
          </Button>
        </Box>
      </Grid>
    </Grid>
  </Container>
</Box>


    </Box>
    
  );
};

export default Footer;
