import React from 'react';
import { Box, Container, Typography } from '@mui/material';

// MapDisplay Component
const MapDisplay = ({ src, width = "100%", height = "450" }) => {
  return (
    <section
      style={{
        position: 'relative',
        paddingTop: '60px',
        paddingBottom: '60px',
        backgroundColor: '#f7f5f1',
      }}
    >
      <Container>
        <Box textAlign="center">
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: '#644222',
              fontSize: '18px',
              marginBottom: '10px',
              textTransform: 'capitalize',
              fontFamily: 'Jost, sans-serif',
              fontStyle: 'normal',
              fontWeight: 600,
              marginTop: 0,
            }}
          >
            Visit Us
          </Typography>

          <Typography
            variant="h2"
            gutterBottom
            style={{
              color: '#101010',
              fontFamily: 'Jost, sans-serif',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '40px',
            }}
          >
            Find Us on the Map
          </Typography>

          <Typography variant="body1" style={{ marginBottom: '40px' }}>
            Easily locate our place on Google Maps using the embedded map below.
          </Typography>

          {/* Embedded Google Map */}
          <Box sx={{ width: '100%', marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
            <iframe
              src={src}
              width="100%"
              height={height}
              frameBorder="0"
              style={{ border: '0' }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </Box>
        </Box>
      </Container>
    </section>
  );
};

export default MapDisplay;
