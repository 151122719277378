import React from "react";
import { Container, Grid2 as Grid, Typography, Box } from "@mui/material";
import { styled } from "@mui/system";
import aboutpart from "../../Assets/Images/aboutpart.png"; // Replace with your image path

// Styled Components
const Section = styled("section")(() => ({
  background: "rgb(41, 29, 22)",
  color: "#fff",
  padding: "0", // No padding around the section
  minHeight: "100vh",
  overflow: "hidden", // Ensures no overflow
}));

const SkillBarWrapper = styled(Box)(() => ({
  backgroundColor: "#fff",
  height: "15px",
  overflow: "hidden",
  marginTop: "10px",
  position: "relative",
}));

const SkillBar = styled(Box)(({ width }) => ({
  height: "100%",
  backgroundColor: "#be9874",
  width: `${width}%`,
  transition: "width 1s ease-in-out",
}));

// Skill Value Box - Position it dynamically relative to the progress bar
const SkillValueBox = styled(Box)(({ width }) => ({
  position: "absolute",
  top: "-5px", // Position it above the progress bar
  right: `${100 - width}%`, // This moves the square to the right position based on the bar width
  backgroundColor: "#be9874",
  color: "#fff",
  fontWeight: "bold",
  width: "30px", // Fixed size of the square
  height: "30px", // Fixed size of the square
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "4px", // Slightly rounded corners for the square
  transform: "translateX(50%)", // Center the square horizontally based on its width
  fontSize: "12px", // Font size for the percentage value

  // Adding the `::after` pseudo-element
  "&::after": {
    content: '""',
    position: "absolute",
    width: "10px",
    height: "10px",
    backgroundColor: "#be9874",
    bottom: "-3px", // Position above the square
    right: '15px',
    transform: "translateX(50%) rotate(45deg)", // Rotate the square
    borderRadius: "2px", // Rounded corners for the small square
  },
}));

const skills = [
  { name: "Quality Production", value: 80 },
  { name: "Maintenance Services", value: 90 },
  { name: "Product Management", value: 70 },
];

const AboutUsPart = () => {
  return (
    <Section sx={{ marginTop: "120px", paddingBottom: "0", marginBottom: "-7.5%" }}>
      <Container maxWidth="xl" sx={{ padding: "0" }}>
        <Grid container>
          {/* Left Section */}
          <Grid
            size={{ sx: 12, md: 6 }}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "60px",
            }}
          >
            <Typography variant="h5" gutterBottom>
              Rio We Use
            </Typography>
            <Typography variant="h3" gutterBottom sx={{ fontWeight: "bold", lineHeight: 1.2 }}>
              We Offer Wide Selection of Hotel
            </Typography>
            <Typography variant="body1" paragraph>
              Vestibulum non ornare nunc. Maecenas a metus in est iaculis pretium. Aliquam ullamcorper nibh
              lacus, ac suscipit ipsum consequat porttitor.
            </Typography>

            <Box mt={4}>
              {skills.map((skill, index) => (
                <Box key={index} mb={4} position="relative">
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    {skill.name}
                  </Typography>
                  <SkillValueBox width={skill.value}>{skill.value}</SkillValueBox>
                  <SkillBarWrapper>
                    <SkillBar width={skill.value}>
                      {/* Skill Value Box will be placed outside the bar */}
                      
                    </SkillBar>
                  </SkillBarWrapper>
                </Box>
              ))}
            </Box>
          </Grid>



          {/* Right Section */}
          <Grid
            size={{ sx: 12, md: 6 }}
            sx={{
              position: "relative",
              padding: "0", // Remove any padding
              margin: "0", // Ensure no margin
              // paddingBottom:"-5px"
            }}
          >
            <Box
              sx={{
                height: "100vh",
                width: "100%",
                position: "relative",
              }}
            >
            <Box
  sx={{
    height: "100%",
    width: "100%",
    position: "relative",
  }}
>
  <img
    src={aboutpart}
    alt="About Us"
    style={{
      width: "100%",
      height: "100%",
      objectFit: "cover",
      display: "block",
      marginLeft:"25px",
    }}
    // Adjusts the height based on screen size
    sx={{
      "@media (max-width: 600px)": {
        height: "auto", // On smaller screens, adjust the height to auto
      },
      "@media (min-width: 600px)": {
        height: "100vh", // On larger screens, keep it 100vh
      },
    }}
  />
</Box>

            </Box>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};

export default AboutUsPart;
