import React from 'react'
import PageLayout from '../../Components/Layout/PageLayout'
import GallerySection from './GallerySection'
import BreadcrumbSectionGallery from './BreadcumbSectionGallery'

const Gallery = () => {
  return (
    <div>
      <PageLayout>
    <BreadcrumbSectionGallery/>
       <GallerySection/>
      </PageLayout>
    </div>
  )
}

export default Gallery
