import React, { useState } from 'react';
import { Box } from '@mui/material';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // Import Lightbox CSS

import gallery1 from '../../Assets/Images/jotibatemple.jpg';
import gallery2 from '../../Assets/Images/mhalaxmitemple.jpg';
import gallery3 from '../../Assets/Images/cinmayganapati.jpg';
import gallery4 from '../../Assets/Images/newpalace.gif';
import gallery5 from '../../Assets/Images/kanerimath.jpg';
import gallery6 from '../../Assets/Images/sangliganapati.jpg';
import gallery7 from '../../Assets/Images/narasobawadi.jpg';
import gallery8 from '../../Assets/Images/panhalafort.jpg';
import gallery9 from '../../Assets/Images/rankalalake.jpg';
import gallery10 from '../../Assets/Images/gallery12.jpg';

const images = [
  { src: gallery1, category: 'Room' },
  { src: gallery2, category: 'Hall' },
  { src: gallery3, category: 'Guardian' },
  { src: gallery4, category: 'Hotel' },
  { src: gallery5, category: 'Event Hall' },
  { src: gallery6, category: 'Room' },
  { src: gallery7, category: 'Hotel' },
  { src: gallery8, category: 'Event Hall' },
  { src: gallery9, category: 'Hall' },
  { src: gallery10, category: 'Guardian' },
];

const Gallery = () => {
  const [filter, setFilter] = useState('All');
  const [lightboxIndex, setLightboxIndex] = useState(-1);

  const handleFilter = (category) => {
    setFilter(category);
  };

  const handleImageClick = (index) => {
    setLightboxIndex(index);
  };

  const filteredImages =
    filter === 'All' ? images : images.filter((img) => img.category === filter);

  return (
    <Box
    sx={{
      paddingTop: '50px', // Add padding to the top
      marginTop: '50px', // Ensure the section has some space above it
    //   marginBottom: '50px', // Optional: Add margin at the bottom for spacing
    }}
  >
      {/* Filter Links */}
      <Box
  className="filter-links"
  mb={5}
  textAlign="center"
  sx={{
    display: 'flex', // Make links horizontal by default
    flexWrap: 'wrap', // Allow wrapping on smaller screens
    justifyContent: 'center', // Center the links
  }}
>
  {/* "All" Link */}
  <a
    href="#"
    onClick={(e) => {
      e.preventDefault(); // Prevent default link behavior
      handleFilter('All'); // Set filter to 'All'
    }}
    style={{
      margin: '0 15px',
      color: filter === 'All' ? '#000' : '#777',
      textDecoration: filter === 'All' ? 'underline' : 'none',
      fontWeight: filter === 'All' ? 'bold' : 'normal',
      cursor: 'pointer',
    }}
    onMouseOver={(e) => e.target.style.color = '#000'} // Hover effect for color change
    onMouseOut={(e) => e.target.style.color = filter === 'All' ? '#000' : '#777'} // Reset color after hover
  >
    All
  </a>

  {/* Add backslash after "All" */}
  <span style={{ margin: '0 5px', color: '#000' }}>/</span>

  {/* Other category links with backslashes */}
  {['Room', 'Hall', 'Guardian', 'Hotel', 'Event Hall'].map((category, index) => (
    <React.Fragment key={category}>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault(); // Prevent default link behavior
          handleFilter(category); // Set filter to the selected category
        }}
        style={{
          margin: '0 15px',
          color: filter === category ? '#000' : '#777',
          textDecoration: filter === category ? 'underline' : 'none',
          fontWeight: filter === category ? 'bold' : 'normal',
          cursor: 'pointer',
        }}
        onMouseOver={(e) => e.target.style.color = '#000'} // Hover effect for color change
        onMouseOut={(e) => e.target.style.color = filter === category ? '#000' : '#777'} // Reset color after hover
      >
        {category}
      </a>

      {/* Add backslash except for the last category */}
      {index < 4 && (
        <span style={{ margin: '0 5px', color: '#777' }}>/</span>
      )}
    </React.Fragment>
  ))}
</Box>






      <Box
        sx={{
          width: '100%', // Full width of the viewport
          padding: 0,
          margin: 0,
        }}
      >
        {/* Gallery Grid */}
        <Box
  className="gallery-grid"
  sx={{
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 2fr)',
    gap: 0,
  }}
>
  {filteredImages.map((img, index) => (
    <Box
      key={index}
      onClick={() => handleImageClick(index)}
      sx={{
        position: 'relative',
        overflow: 'hidden',
        cursor: 'pointer',
        gridColumn: index % 3 === 0 ? 'span 2' : 'span 1', // Vary column spans
        gridRow: index % 4 === 0 ? 'span 2' : 'span 1', // Vary row spans
      }}
    >
      <img
        src={img.src}
        alt={`Gallery Item ${index + 1}`}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          display: 'block',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: '#000', // Initial black background color
          opacity: 0, // Initially hidden
          transition: 'opacity 0.3s ease-in-out, background-color 0.3s ease-in-out', // Transition effects
          '&:hover': { 
            opacity: 1, 
            backgroundColor: 'rgba(100, 66, 34, 0.9)', // Adjust the 0.7 to control transparency

          },
        }}
      />
    </Box>
  ))}
</Box>

      </Box>

      {/* Lightbox */}
      {lightboxIndex >= 0 && (
        <Lightbox
          mainSrc={filteredImages[lightboxIndex].src}
          nextSrc={
            filteredImages[(lightboxIndex + 1) % filteredImages.length].src
          }
          prevSrc={
            filteredImages[
              (lightboxIndex + filteredImages.length - 1) %
                filteredImages.length
            ].src
          }
          onCloseRequest={() => setLightboxIndex(-1)}
          onMovePrevRequest={() =>
            setLightboxIndex(
              (lightboxIndex + filteredImages.length - 1) %
                filteredImages.length
            )
          }
          onMoveNextRequest={() =>
            setLightboxIndex((lightboxIndex + 1) % filteredImages.length)
          }
        />
      )}
    </Box>
  );
};

export default Gallery;
