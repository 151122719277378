import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import { styled } from '@mui/system';
import about1 from '../../Assets/Images/about1.png';
import about2 from '../../Assets/Images/about2.png';
import about3 from '../../Assets/Images/hotel.jpg';
import about4 from '../../Assets/Images/kedarnew.jpg';
import sign from '../../Assets/Images/sign.png';
import './AboutSection.css';

const CustomButton = styled(Button)(({ theme }) => ({
  background: '#644222',
  color: '#fff',
  padding: '20px 30px',
  fontSize: '16px',
  textTransform: 'inherit',
  '&:hover': {
    backgroundColor: '#4b3621',
  },
}));

const AboutSection = () => {
  return (
    <section className="about-section-container">
      <div className="container">
        <Grid container spacing={3} alignItems="center">
          {/* Left side: Image Section */}
          <Grid item xs={12} sm={6}>
            <Box className="about-image-container">
              <img src={about4} alt="About Us" className="about-image" />
              <img src={about3} alt="Icon" className="about-icon-image" />
            </Box>
          </Grid>

          {/* Right side: Information Section */}
          <Grid item xs={12} sm={6}>
            <Box className="about-content">
              <div className="about-title">
                <h5>About Us</h5>
                <h2>Most Safe & Rated Hotel In Kolhapur</h2>
              </div>
              <Typography variant="body1" paragraph>
              Since 1955, we are in bakery industry. Specially in Kolhapur we have developed an expanding market of delicious & tasty products which people appreciate. We prepare every product hygienically. Considering the growing market of bakery products; we are ready to face upcoming challenges. Our state of the art producton unit is equipped with the most modern machines for extra production in less man hours.
              </Typography>
              <Typography variant="body1" paragraph>
                Our state of the art producton unit is equipped with the most modern machines for extra production in less man hours. The credit of our success goes to Late H. B. P. Dattoba Kushappa Khade who established Kedarling Bakery way back in 1955. With a home based production unit & a sales counter, he started this business.
              </Typography>
              {/* <ul className="styled-list">
                <li>✔ 24 Month / 24,000km Nationwide Warranty monotone</li>
                <li>✔ Curabitur dapibus nisl a urna congue...</li>
                <li>✔ Customer Rewards Program...</li>
              </ul> */}
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={6}>
                  <CustomButton href="/about">Discover More</CustomButton>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <img src={sign} alt="Signature" className="signature-img" />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default AboutSection;
