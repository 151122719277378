import React from 'react';
import { Box, Typography, IconButton,Link } from '@mui/material';
import taketour from "../../Assets/Images/taketour.png"
import video from "../../Assets/Images/video.png"

const TakeTour = () => {
  return (
    <Box
      id="video"
      className="video-area"
      sx={{
        pt: 15, // padding-top: 150px
        pb: 15, // padding-bottom: 150px
        position: 'relative',
        backgroundImage: `url(${taketour})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box className="content-lines-wrapper2" sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
        <Box className="content-lines-inner2" sx={{ position: 'absolute', top: 0, left: 0, width: '100%',  }}>
          <Box className="content-lines2" sx={{ position: 'absolute', top: '50%', left: '50%', width: '100%',  }} />
        </Box>
      </Box>
      
      <Box className="container">
        <Box className="row">
          <Box className="col-12">
            <Box className="s-video-wrap" sx={{ textAlign: 'center' }}>
              <Box className="s-video-content">
                <Link className="popup-video" to="/room">
                  <IconButton>
                    <img src={video} alt="circle_right" />
                  </IconButton>
                </Link>
              </Box>
            </Box>
            <Box >
              <Typography variant='h2' sx={{ textAlign: 'center', color: '#fff',  fontFamily:'Jost, sans-serif', fontSize:'50px',fontStyle:'normal',fontWeight
              :600,
              }}>
                Take A Tour Of Luxuri
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TakeTour;
