import React, { useState } from "react";
import {
  TextField,
  MenuItem,
  Select,
  Button,
  FormControl,
  Typography,
  Box,
  
} from "@mui/material";
import Grid from "@mui/material/Grid";
import bookroom from "../../Assets/Images/bookroom.png";
import bookroomanimation from "../../Assets/Images/bookroomanimation.png";
import RoomServiceOutlinedIcon from "@mui/icons-material/RoomServiceOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const BookRoom = () => {
  const [checkInDate, setCheckInDate] = useState(null);
  const [checkOutDate, setCheckOutDate] = useState(null);
  const [adults, setAdults] = useState("");
  const [room, setRoom] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log({ checkInDate, checkOutDate, adults, room });
  };

  return (
    <Box
      component="section"
      py={6}
      position="relative"
      marginTop={6}
      sx={{
        px: { xs: 2, sm: 4, md: 6, lg: 8 },
      }}
    >
      <Box position="absolute" top={0} left={0} zIndex={-1}>
        <img src={bookroomanimation} alt="animation" style={{ maxWidth: "100%" }} />
      </Box>
      <Box mx="auto" px={4}>
        <Grid container spacing={3} alignItems="center">
          {/* Form Section */}
          <Grid item lg={6} md={6} xs={12}>
            <Box>
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  color: "#644222", // Corrected color syntax
                  fontSize: "18px", // Corrected font-size syntax
                  marginBottom: "10px", // Corrected margin-bottom syntax
                  textTransform: "capitalize", // Corrected text-transform syntax
                  fontFamily: "Jost, sans-serif",
                  fontWeight: "bold",


                }}
              >
                Make Appointment
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  color: "#101010",
                  fontWeight: "bold",
                  // textAlign: "center",
                  marginBottom: "30px",
                  fontFamily: "Jost, sans-serif",


                }}
              >
                Book A Room
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Box display="flex" alignItems="center" gap={1} mb={1}>
                      <CheckCircleOutlineOutlinedIcon
                        style={{ fontSize: "25px", color: "#be9874" }}
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#101010",
                          fontWeight: "500",
                          textTransform: "capitalize",
                          fontSize: "16px",
                  fontFamily: "Jost, sans-serif",

                        }}
                      >
                        Check In Date
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      type="date"
                      value={checkInDate}
                      onChange={(e) => setCheckInDate(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        style: {
                          color: "#926d63", // Set text color here
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          // backgroundColor: "#926d63", // Keep the background color
                          borderRadius:0,
                          borderColor: "#be9874", // Set border color
                          color: "#926d63", // Text color
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#be9874", // Set border color
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#be9874", // Border color on hover
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#000", // Border color when focused (black)
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box display="flex" alignItems="center" gap={1} mb={1}>
                      <CancelOutlinedIcon
                        style={{ fontSize: "25px", color: "#be9874" }}
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#101010",
                          fontWeight: "500",
                          textTransform: "capitalize",
                          fontSize: "16px",
                          fontFamily: "Jost, sans-serif",
                        }}
                      >
                        Check Out Date
                      </Typography>
                    </Box>
                    <TextField
                      fullWidth
                      type="date"
                      value={checkOutDate}
                      onChange={(e) => setCheckOutDate(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        style: {
                          color: "#926d63", // Set text color here
                          // borderColor: "#926d63", // Set border color

                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          // backgroundColor: "#926d63", // Keep the background color
                          borderRadius:0,
                          borderColor: "#926d63", // Set border color
                          color: "#926d63", // Text color
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#be9874", // Set border color
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#be9874", // Border color on hover
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#000", // Border color when focused (black)
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box display="flex" alignItems="center" gap={1} mb={1}>
                      <GroupOutlinedIcon
                        style={{ fontSize: "25px", color: "#be9874" }}
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#101010",
                          fontWeight: "500",
                          textTransform: "capitalize",
                          fontSize: "16px",
                          fontFamily: "Jost, sans-serif",
                        }}
                      >
                        Adults
                      </Typography>
                    </Box>
                    <FormControl fullWidth>
                      <Select
                        value={adults}
                        onChange={(e) => setAdults(e.target.value)}
                        displayEmpty
                        sx={{
                          color: "#926d63", // Text color
                          borderRadius: "0",
                          fontWeight: "500",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#be9874", // Set border color
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#be9874", // Border color on hover
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#000", // Border color when focused (black)
                          },
                        }}
                      >
                        <MenuItem value="" disabled>
                          Adults
                        </MenuItem>
                        {[1, 2, 3, 4, 5].map((num) => (
                          <MenuItem key={num} value={num}>
                            {num}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box display="flex" alignItems="center" gap={1} mb={1}>
                      <RoomServiceOutlinedIcon
                        style={{ fontSize: "25px", color: "#be9874" }}
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#101010",
                          fontWeight: "500",
                          textTransform: "capitalize",
                          fontSize: "16px",
                          fontFamily: "Jost, sans-serif",
                        }}
                      >
                        Room
                      </Typography>
                    </Box>
                    <FormControl fullWidth>
                      <Select
                        value={room}
                        onChange={(e) => setRoom(e.target.value)}
                        displayEmpty
                        sx={{
                          
                          color: "#926d63", // Text color
                          borderRadius: "0",
                          fontWeight: "500",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#be9874", // Set border color
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#be9874", // Border color on hover
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#000", // Border color when focused (black)
                          },
                        }}
                      >
                        <MenuItem value="" disabled>
                          Room
                        </MenuItem>
                        {[1, 2, 3, 4, 5].map((num) => (
                          <MenuItem key={num} value={num}>
                            {num}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                  <Button
  type="submit"
  variant="contained"
  fullWidth
  sx={{
    backgroundColor: "#644222", // Corrected hover background color
    border: "none", // Set border to none
    borderRadius: 0, // Ensure border-radius is set to 0
    color: "#fff", // Keep text color white
    display: "inline-block", // Set display to inline-block
    fontSize: "16px", // Set font size
    letterSpacing: "0", // Remove letter-spacing
    lineHeight: "1", // Set line height
    marginBottom: "0", // Remove bottom margin
    padding: "20px 30px", // Adjust padding for hover
    position: "relative", // Ensure position is set to relative
    textAlign: "center", // Text alignment
    textTransform: "uppercase", // Ensure text is uppercase
    touchAction: "manipulation", // Set touch-action to manipulation
    transition: "all .3s ease", // Smooth transition for all properties
    verticalAlign: "middle", // Set vertical alignment
    whiteSpace: "nowrap", // Prevent text from wrapping
    zIndex: 1, // Ensure button is above other elements
    // fontWeight: "bold",

   
   
    "&:hover": {
      backgroundColor: "#644222",
      color: "#fff",
      // fontWeight: "bold",
      padding: "20px 30px",
      borderRadius: 0, 
    },
  }}
>
  Book Table Now
</Button>

                  </Grid>
                </Grid>
              </form>
            </Box>
          </Grid>

          {/* Image Section */}
          <Grid item lg={6} md={6} xs={12}>
            <Box>
              <img
                src={bookroom}
                alt="Room Booking"
                style={{
                  width: "100%",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default BookRoom;
