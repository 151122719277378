import React, { useState } from "react";
import {
  TextField,
  MenuItem,
  Button,
  FormControl,
  Select,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import PersonIcon from "@mui/icons-material/Person";
import RoomServiceOutlinedIcon from "@mui/icons-material/RoomServiceOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

import "./Booking.css";

const Booking = () => {
  const [checkinDate, setCheckinDate] = useState(null);
  const [checkoutDate, setCheckoutDate] = useState(null);
  const [adults, setAdults] = useState("1");
  const [children, setChildren] = useState("0");
  const [rooms, setRooms] = useState("1");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({
      checkinDate,
      checkoutDate,
      adults,
      children,
      rooms,
    });
  };

  return (
    <div id="booking" className="booking-area p-relative">
      <div className="container">
        <form onSubmit={handleSubmit} className="contact-form">
          <Grid container spacing={3}>
            {/* Check-In Date */}
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Box display="flex" alignItems="center" gap={1}>
                  <CheckCircleOutlineOutlinedIcon style={{ fontSize: "30px" }} />
                  <Typography variant="body1">Check-In Date</Typography>
                </Box>
                <FormControl fullWidth required>
                  <TextField
                    type="date"
                    value={checkinDate ? dayjs(checkinDate).format("YYYY-MM-DD") : ""}
                    onChange={(e) => setCheckinDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
              </Box>
            </Grid>

            {/* Check-Out Date */}
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Box display="flex" alignItems="center" gap={1}>
                  <CancelOutlinedIcon style={{ fontSize: "30px" }} />
                  <Typography variant="body1">Check-Out Date</Typography>
                </Box>
                <FormControl fullWidth required>
                  <TextField
                    type="date"
                    value={checkoutDate ? dayjs(checkoutDate).format("YYYY-MM-DD") : ""}
                    onChange={(e) => setCheckoutDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
              </Box>
            </Grid>

            {/* Adults */}
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Box display="flex" alignItems="center" gap={1}>
                  <GroupOutlinedIcon style={{ fontSize: "30px" }} />
                  <Typography variant="body1">Adults</Typography>
                </Box>
                <FormControl fullWidth required>
                  <Select
                    value={adults}
                    onChange={(e) => setAdults(e.target.value)}
                  >
                    {[1, 2, 3, 4, 5].map((num) => (
                      <MenuItem key={num} value={num}>
                        {num}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            {/* Children */}
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Box display="flex" alignItems="center" gap={1}>
                  <PersonIcon style={{ fontSize: "30px" }} />
                  <Typography variant="body1">Children</Typography>
                </Box>
                <FormControl fullWidth required>
                  <Select
                    value={children}
                    onChange={(e) => setChildren(e.target.value)}
                  >
                    {[0, 1, 2, 3, 4, 5].map((num) => (
                      <MenuItem key={num} value={num}>
                        {num}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            {/* Rooms */}
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Box display="flex" alignItems="center" gap={1}>
                  <RoomServiceOutlinedIcon style={{ fontSize: "30px" }} />
                  <Typography variant="body1">Rooms</Typography>
                </Box>
                <FormControl fullWidth required>
                  <Select
                    value={rooms}
                    onChange={(e) => setRooms(e.target.value)}
                  >
                    {[1, 2, 3, 4, 5].map((num) => (
                      <MenuItem key={num} value={num}>
                        {num}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100%"
                gap={2}
              >
                <CalendarMonthOutlinedIcon style={{ fontSize: "30px" }} />
                <Button
  type="submit"
  variant="contained"
  fullWidth
  sx={{
    backgroundColor: '#644222',  // Corrected property name for background
    border: 'none',
    borderRadius: 0,  // Ensure the border-radius is 0
    color: '#fff',
    display: 'inline-block',
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '1',
    marginBottom: '0',
    padding: '20px 30px',
    position: 'relative',
    textAlign: 'center',
    textTransform: 'inherit',
    touchAction: 'manipulation',
    transition: 'all 0.3s ease',  // Corrected transition syntax
    verticalAlign: 'middle',
    whiteSpace: 'nowrap',
    zIndex: 1,
    textDecoration: 'none',
  }}
>
  Check Availability
</Button>

              </Box>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default Booking;
