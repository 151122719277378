import React from "react";
import { Container, Grid, Typography, Card, CardMedia, CardContent, Box } from "@mui/material";

// Import images
import panhala from "../../Assets/Images/panhala.jpg";
import kaneriMath from "../../Assets/Images/kanheri_math.jpg";
import panchganga from "../../Assets/Images/panchgnga.webp";
import newPalace from "../../Assets/Images/rajvada.jpg";
import rankala from "../../Assets/Images/rankala2.jpg";
import shivajiUniversity from "../../Assets/Images/Shivaji-University-Kolhapur1.jpg";
import shaliniPalace from "../../Assets/Images/shalini-palace.jpg";
import narsobachiWadi from "../../Assets/Images/narasobawadi.jpg";

const galleryData = [
  { title: "Panhala", img: panhala },
  { title: "Kaneri Math", img: kaneriMath },
  { title: "Panchganga Wadi", img: panchganga },
  { title: "New Palace", img: newPalace },
  { title: "Rankala", img: rankala },
  { title: "Shivaji University", img: shivajiUniversity },
  { title: "Shalini Palace", img: shaliniPalace },
  { title: "Narsobachi Wadi", img: narsobachiWadi },
];

const PicnicPoint = () => {
  return (
    <Container maxWidth="xl" sx={{ padding: "30px" }}>
      <Box sx={{ textAlign: "center", marginBottom: "20px" }}>
            <Typography
                 variant="h2"
                 align="center"
                 gutterBottom
                 sx={{
                   color: '#101010',
                   fontFamily: 'Jost, sans-serif',
                   fontStyle: 'normal',
                   fontWeight: 600,
                   marginTop: 0,
                   textTransform: 'normal',
                   marginBottom: '15px',
                 }}
               >
             Picnic Points in kolhapur
               </Typography>
        <Box
          sx={{
            width: "50px",
            height: "4px",
            // backgroundColor: "blue",
            margin: "10px auto",
          }}
        />
      </Box>
      <Grid container spacing={3}>
        {galleryData.map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card sx={{ boxShadow: 3 }}>
              <a href={item.img} title={item.title} target="_blank" rel="noopener noreferrer">
                <CardMedia
                  component="img"
                  height="200"
                  image={item.img}
                  alt={item.title}
                />
              </a>
              <CardContent>
                <Typography variant="h6" textAlign="center">
                  {item.title}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default PicnicPoint;
