import React from 'react';
import { Box, Card, CardMedia, CardContent, Typography, List, ListItem ,Link} from '@mui/material';
import roomImg01 from '../../Assets/Images/roomImg01.png';
import roomImg02 from '../../Assets/Images/roomImg02.png';
import roomImg03 from '../../Assets/Images/roomImg03.png';
import roomImg04 from '../../Assets/Images/roomImg04.png';
import roomImg05 from '../../Assets/Images/roomImg05.png';
import roomImg06 from '../../Assets/Images/roomImg06.png';
import wifi from '../../Assets/Images/wifi.png';
import fourwheeler from '../../Assets/Images/fourwheeler.png';
import car from '../../Assets/Images/car.png';
import tea from '../../Assets/Images/tea.png';
import food from '../../Assets/Images/food.png';
import swim from '../../Assets/Images/swim.png';

const roomsData = [
  {
    img: roomImg01,
    price: '$600/Night',
    title: 'Classic Balcony Room',
    description: 'Aenean vehicula ligula eu rhoncus porttitor. Duis vel lacinia quam. Nunc rutrum porta place ullam ipsum. Morbi imperdiet, orci et dapibus.',
    icons: [wifi, fourwheeler, car, tea, food, swim],
    links: {
      room: '/room',
      details: '',
      contact: '/contact',
    },
  },
  {
    img: roomImg02,
    price: '$400/Night',
    title: 'Superior Double Room',
    description: 'Aenean vehicula ligula eu rhoncus porttitor. Duis vel lacinia quam. Nunc rutrum porta ex, in imperdiet tortor feugiat at.',
    icons: [wifi, fourwheeler, car, tea, food, swim],
    links: {
      room: '/room',
      details: '',
      contact: '/contact',
    },
  },
  {
    img: roomImg03,
    price: '$100/Night',
    title: 'Super Balcony Double Room',
    description: 'Aenean vehicula ligula eu rhoncus porttitor. Duis vel lacinia quam. Nunc rutrum porta place ullam ipsum. Morbi imperdiet, orci et dapibus.',
    icons: [wifi, fourwheeler, car, tea, food, swim],
    links: {
      room: '/room',
      details: '',
      contact: '/contact',
    },
  },
  {
    img: roomImg04,
    price: '$600/Night',
    title: 'Classic Balcony Room',
    description: 'Aenean vehicula ligula eu rhoncus porttitor. Duis vel lacinia quam. Nunc rutrum porta place ullam ipsum. Morbi imperdiet, orci et dapibus.',
    icons: [wifi, fourwheeler, car, tea, food, swim],
    links: {
      room: '/room',
      details: '',
      contact: '/contact',
    },
  },
  {
    img: roomImg05,
    price: '$400/Night',
    title: 'Superior Double Room',
    description: 'Aenean vehicula ligula eu rhoncus porttitor. Duis vel lacinia quam. Nunc rutrum porta ex, in imperdiet tortor feugiat at.',
    icons: [wifi, fourwheeler, car, tea, food, swim],
    links: {
      room: '/room',
      details: '',
      contact: '/contact',
    },
  },
  {
    img: roomImg06,
    price: '$100/Night',
    title: 'Super Balcony Double Room',
    description: 'Aenean vehicula ligula eu rhoncus porttitor. Duis vel lacinia quam. Nunc rutrum porta place ullam ipsum. Morbi imperdiet, orci et dapibus.',
    icons: [wifi, fourwheeler, car, tea, food, swim],
    links: {
      room: '/room',
      details: '',
      contact: '/contact',
    },
  },
];

const RoomCard = () => {
  return (
    <Box component="section" id="services" className="services-area" py={4} px={4} sx={{ marginBottom: "-80px" }}>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: 4, // Spacing between cards
        }}
      >
        {roomsData.map((room, index) => (
          <Box key={index} sx={{ flex: '1 1 100%', maxWidth: 400 }}>
            <Card
              sx={{
                height: '100%',
                position: 'relative',
                borderRadius: 0,
                boxShadow: 3,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <CardMedia
                component="img"
                height="300"
                image={room.img}
                alt={room.title}
                sx={{
                  borderRadius: 0,
                  objectFit: 'cover',
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: 260,
                  left: 40,
                  right: 40,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  bgcolor: '#644222',
                  color: '#fff',
                  fontFamily: "'Jost', sans-serif",
                  fontSize: '16px',
                  fontWeight: '600',
                  p: 2,
                }}
              >
                <Typography variant="h6" sx={{ fontFamily: "'Jost', sans-serif", fontWeight: '600', fontSize: '16px', paddingLeft: '16px' }}>
                  {room.price}
                </Typography>
                <Link to={room.links.contact} style={{ color: '#fff', textDecoration: 'none', fontWeight: '600', paddingRight: '16px' }}>
                  Book Now
                </Link>
              </Box>
              <CardContent sx={{ mt: 4 }}>
                <Typography
                  variant="h5"
                  component="a"
                  to={room.links.details}
                  gutterBottom
                  sx={{
                    display: 'block',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    marginBottom: 2,
                    textDecoration: 'none',
                    color: 'inherit',
                  }}
                >
                  {room.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  paragraph
                  sx={{
                    marginBottom: 2,
                    padding: 2,
                    lineHeight: 1.8,
                  }}
                >
                  {room.description}
                </Typography>
                <List disablePadding sx={{ display: 'flex', gap: 1, marginBottom: 5 }}>
                  {room.icons.map((icon, idx) => (
                    <ListItem key={idx} disablePadding>
                      <img src={icon} alt={`icon-${idx}`} width={24} />
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default RoomCard;
