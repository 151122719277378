import React from 'react';
import Aboutus from '../../Assets/Images/Aboutus.jpg'; // Import the image
import './BreadcrumbSectionFacilities.css'
import { Link } from 'react-router-dom';

// React functional component for the Breadcrumb section
const BreadcrumbSectionFacilities = () => {
  return (
    <section
      className="breadcrumb-area d-flex align-items-center justify-content-center"
      style={{
        backgroundImage: `url(${Aboutus})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        minHeight: '450px',
        position: 'relative',
      }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-12 col-lg-12">
            <div className="breadcrumb-wrap text-center">
              <div className="breadcrumb-title">
                <h2>Facilities</h2>
                <div className="breadcrumb-wrap">
                  <nav aria-label="breadcrumb">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item active" aria-current="page">
                        Facilities
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BreadcrumbSectionFacilities;
