import React from 'react';
import { Container, Grid2 as Grid, Typography, TextField, Button, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FaMapMarkerAlt, FaClock, FaEnvelopeOpen } from 'react-icons/fa';

const useStyles = makeStyles((theme) => ({
  section: {
    paddingTop: '120px',
    paddingBottom: '120px',
    position: 'relative',
    backgroundColor: '#fff',
  },
  contactInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
  },
  ctaBox: {
    display: 'flex',
    gap: '30px',
    alignItems: 'center',
    paddingBottom: '10px',
    marginBottom: '5px',
    animation: 'fadeInUp 1s',
  },
  formBox: {
    marginTop: '30px',
  },
  formField: {
    marginBottom: '20px',
    '& .MuiOutlinedInput-root': {
      borderRadius: 0, // Apply border radius
      '& fieldset': {
        borderColor: '#000', // Apply border color
      },
      '&:hover fieldset': {
        borderColor: '#555', // Optional: change color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: '#000', // Keep border color when focused
      },
     
    },
  },
  

  iconBox: {
    background: '#644222',
    borderRadius: '50%',
    color: '#fff',
    display: 'inline-block',
    fontSize: '28px',
    height: '80px',
    lineHeight: '78px',
    textAlign: 'center',
    width: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  horizontalLine: {
    border: '0',
    borderTop: '1px solid #ddd',
  },
  formBox: {
    background: '#f7f5f1',
    padding: '50px',
    textAlign: 'center',
    borderRadius: '0',
  },
}));

const ContactUs = () => {
  const classes = useStyles();

  return (
    <section id="contact" className={classes.section}>
      <Container>
        <Grid container justifyContent="center" alignItems="center" spacing={4}>
          {/* Contact Info Section */}
          <Grid size={{lg:4, sm:12}} >
            <div className={classes.contactInfo}>
              <div className={classes.ctaBox}>
                <Box className={classes.iconBox}>
                  <FaMapMarkerAlt size={30} />
                </Box>
                <div>
                  <Typography
                    variant="h6"
                    style={{
                      marginBottom: '20px',
                      color: '#101010',
                      fontFamily: 'Jost, sans-serif',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      marginTop: 0,
                      fontSize: '20px',
                    }}
                  >
                    Office Address
                  </Typography>
                  <Typography>
                  Kedarling Yatri Nivas,
Mahalxmi <br/>Temple, Tarabai Road,
 <br />Opp. Babujamal Darga, Kolhapur
                  </Typography>
                </div>
              </div>
              <hr className={classes.horizontalLine} />
              <div className={classes.ctaBox}>
                <Box className={classes.iconBox}>
                  <FaClock size={30} />
                </Box>
                <div>
                  <Typography
                    variant="h6"
                    style={{
                      marginBottom: '20px',
                      color: '#101010',
                      fontFamily: 'Jost, sans-serif',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      marginTop: 0,
                      fontSize: '20px',
                    }}
                  >
                    Working Hours
                  </Typography>
                  <Typography>
                    Monday to Friday 09:00 to 18:30 <br />Saturday 15:30
                  </Typography>
                </div>
              </div>
              <hr className={classes.horizontalLine} />
              <div className={classes.ctaBox}>
                <Box className={classes.iconBox}>
                  <FaEnvelopeOpen size={30} />
                </Box>
                <div>
                  <Typography
                    variant="h6"
                    style={{
                      marginBottom: '20px',
                      color: '#101010',
                      fontFamily: 'Jost, sans-serif',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      marginTop: 0,
                      fontSize: '20px',
                    }}
                  >
                    Message Us
                  </Typography>
                  <Typography>
  <a href="mailto:support@example.com" style={{ textDecoration: 'none',color:'#545454' }}>info@kedarlingyatrinivas.com</a>
  <br />
  {/* <a href="mailto:info@example.com" style={{ textDecoration: 'none', color:'#545454' }}>info@example.com</a> */}
</Typography>

                </div>
              </div>
            </div>
          </Grid>

          {/* Contact Form Section */}
          <Grid size={{lg:8,sm:12}} >
            <Box className={classes.formBox} >
            <Typography 
  variant="h4" 
  align="center" 
  gutterBottom 
  style={{
    color: '#101010',
    fontFamily: 'Jost, sans-serif',
    fontStyle: 'normal',
    fontWeight: 600,
    marginTop: 0,
    fontSize: '40px',
    marginBottom: '30px', 
  }}
>
  Get In Touch
</Typography>

              <form>
                <Grid container spacing={2}>
                  {/* First Name */}
                  <Grid size={{xs:12, sm:6}} >
                    <TextField
                      label="First Name"
                      variant="outlined"
                      fullWidth // Ensure full width for First Name
                      className={classes.formField}
                    />
                  </Grid>

                  {/* Email */}
                  <Grid size={{xs:12, sm:6}} >
                    <TextField
                      label="Email"
                      variant="outlined"
                      fullWidth
                      className={classes.formField}
                    />
                  </Grid>

                  {/* Phone No. */}
                  <Grid size={{xs:12, sm:6}} >
                    <TextField
                      label="Phone No."
                      variant="outlined"
                      fullWidth
                      className={classes.formField}
                    />
                  </Grid>

                  {/* Subject */}
                  <Grid size={{xs:12, sm:6}} >
                    <TextField
                      label="Subject"
                      variant="outlined"
                      fullWidth
                      className={classes.formField}
                    />
                  </Grid>

                  {/* Comments Section */}
                  <Grid size={{xs:12}} >
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      label="Write Comments"
                      variant="outlined"
                      className={classes.formField}
                    />
                  </Grid>
                </Grid>

                {/* Submit Button */}
                <Box mt={3} display="flex" justifyContent="center">
                  <Button variant="contained" type="submit" style={{backgroundColor: '#644222',
    color: '#fff',
    display: 'inline-block',
    fontSize: '16px',
    width: '100%',
    padding: '15px 60px',
    textAlign: 'center',
    textTransform: 'uppercase',
    zIndex: 1,
  borderRadius:0,
    '&:hover': {
      backgroundColor: '#000', // Lighter shade on hover
    },

}}>
                    Submit Now
                  </Button>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default ContactUs;
