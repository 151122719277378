import React from 'react';



// import './About.css';


import ServiceSection from './ServiceSection';
import BreadcrumbSectionFacilities from './BreadcumbSectionFacilities';
import PageLayout from '../../Components/Layout/PageLayout';
import LuxuryHotel from '../Home/LuxuryHotel';
import BookRoom from '../Room/BookRoom';
import Testimonials from '../Home/TestimonialSlider';

const Facilities = () => {
  return (
    <div>
      {/* Display headers */}
     <PageLayout>
     <BreadcrumbSectionFacilities/>

{/* About Section */}
<ServiceSection />
{/* <LuxuryHotel/>
<BookRoom/>
<Testimonials/> */}

     </PageLayout>

      {/* Breadcrumb section */}
     
    </div>
  );
};

export default Facilities;
