// HotelSection.js
import React from 'react';
import { Grid2 as Grid } from '@mui/material';
import './HotelSection.css'; // Optional: add styles for the component
// import ServiceSection from './ServiceSection';
const HotelSection = () => {
  return (
    <div>
    <Grid item xs={12} md={6} className="hotel-section-container">
      <div className="section-title center-align mb-50 text-center">
        <h5>Explore</h5>
        <h2>The Hotel</h2>
        <p>
          Proin consectetur non dolor vitae pulvinar. Pellentesque sollicitudin dolor eget neque viverra,
          sed interdum metus interdum. Cras lobortis pulvinar dolor, sit amet ullamcorper dolor iaculis vel.
        </p>
      </div>
    </Grid>
</div>
  );
};

export default HotelSection;
