import React from 'react';
import PageLayout from '../../Components/Layout/PageLayout';
import ContactUs from './ContactUs';
import Newsletter from '../AboutUs/Newsletter';
import BreadcrumbSectionContactUs from '../Contact/BreadcrumbSectionContactUs';
import MapDisplay from './MapDisplay'; // Import the MapDisplay component

const Contact = () => {
  // Google Maps iframe URL
  const mapSrc = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3821.655639138097!2d74.21566031744385!3d16.69410700000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc1000a6a0f3a4b%3A0xfa7e85de095fabc!2sKedarling%20Yatri%20Nivas!5e0!3m2!1sen!2sin!4v1609755732016!5m2!1sen!2sin";

  return (
    <div>
      <PageLayout>
        <BreadcrumbSectionContactUs />
        <ContactUs />
        {/* <Newsletter /> */}
        <MapDisplay src={mapSrc} /> {/* Using the MapDisplay component to render the map */}
      </PageLayout>
    </div>
  );
};

export default Contact;
