// ServiceSection.js
import React from 'react';
import { Grid } from '@mui/material';  // Import MUI's Grid component
import './ServiceSection.css';  // Optional: Add custom styles for the component
import service1 from '../../Assets/Images/service1.png';
import service2 from '../../Assets/Images/service2.png';
import service3 from '../../Assets/Images/service3.png';
import service4 from '../../Assets/Images/service4.png';
import service5 from '../../Assets/Images/service5.png';
import service6 from '../../Assets/Images/service6.png';
import service7 from '../../Assets/Images/free-wifi.gif';
import service8 from '../../Assets/Images/living-room.gif';
import service9 from '../../Assets/Images/air-conditioner.gif';
import service10 from '../../Assets/Images/watching-movie.gif';
import service11 from '../../Assets/Images/payment.gif';
import service12 from '../../Assets/Images/elevator.gif';
import service13 from '../../Assets/Images/mineral-water.gif';
import service14 from '../../Assets/Images/24-7.gif';
import service15 from '../../Assets/Images/parking.gif';
import service16 from '../../Assets/Images/destination.gif';
import service17 from '../../Assets/Images/distance.gif';


const ServiceSection = () => {
  return (
    <div className="service-section-container">
      {/* Main container for all the cards */}
      <Grid container spacing={4}> 
        {/* Card 1 */}
        <Grid item xs={12} sm={6} md={4}>
          <div className="services-card">
            <div className="services-icon">
            <img src={service16} alt="About Us" className="about-image" />

            </div>
            <div className="services-content">
              <h3>
              Near To Mahalxmi Temple
              </h3>
              <p>
                Nullam molestie lacus sit amet velit fermentum feugiat. Mauris auctor eget nunc sit amet.
              </p>
              <a to="" className="read-more-link">
                Read More <i className="fal fa-long-arrow-right"></i>
              </a>
            </div>
          </div>
        </Grid>

        {/* Card 2 */}
        <Grid item xs={12} sm={6} md={4}>
          <div className="services-card">
            <div className="services-icon">
            <img src={service17} alt="About Us" className="about-image" />

            </div>
            <div className="services-content">
              <h3>
               Near from Rankala Lake
              </h3>
              <p>
                Nullam molestie lacus sit amet velit fermentum feugiat. Mauris auctor eget nunc sit amet.
              </p>
              <a to="" className="read-more-link">
                Read More <i className="fal fa-long-arrow-right"></i>
              </a>
            </div>
          </div>
        </Grid>

        {/* Card 3 */}
        <Grid item xs={12} sm={6} md={4}>
          <div className="services-card">
            <div className="services-icon">
            <img src={service15} alt="About Us" className="about-image" />

            </div>
            <div className="services-content">
              <h3>
               Car Parking Available
              </h3>
              <p>
                Nullam molestie lacus sit amet velit fermentum feugiat. Mauris auctor eget nunc sit amet.
              </p>
              <a to="" className="read-more-link">
                Read More <i className="fal fa-long-arrow-right"></i>
              </a>
            </div>
          </div>
        </Grid>

        {/* Card 4 */}
        <Grid item xs={12} sm={6} md={4}>
          <div className="services-card">
            <div className="services-icon">
            <img src={service14} alt="About Us" className="about-image" />

            </div>
            <div className="services-content">
              <h3>
                24 Hour Water
              </h3>
              <p>
                Nullam molestie lacus sit amet velit fermentum feugiat. Mauris auctor eget nunc sit amet.
              </p>
              <a to="" className="read-more-link">
                Read More <i className="fal fa-long-arrow-right"></i>
              </a>
            </div>
          </div>
        </Grid>

        {/* Card 5 */}
        <Grid item xs={12} sm={6} md={4}>
          <div className="services-card">
            <div className="services-icon">
            <img src={service13} alt="About Us" className="about-image" />

            </div>
            <div className="services-content">
              <h3>
             Aquagaurd Drinking Water
              </h3>
              <p>
                Nullam molestie lacus sit amet velit fermentum feugiat. Mauris auctor eget nunc sit amet.
              </p>
              <a to="" className="read-more-link">
                Read More <i className="fal fa-long-arrow-right"></i>
              </a>
            </div>
          </div>
        </Grid>

        {/* Card 6 */}
        <Grid item xs={12} sm={6} md={4}>
          <div className="services-card">
            <div className="services-icon">
            <img src={service12} alt="About Us" className="about-image" />

            </div>
            <div className="services-content">
              <h3>
                Lift Available
              </h3>
              <p>
                Nullam molestie lacus sit amet velit fermentum feugiat. Mauris auctor eget nunc sit amet.
              </p>
              <a to="" className="read-more-link">
                Read More <i className="fal fa-long-arrow-right"></i>
              </a>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className="services-card">
            <div className="services-icon">
            <img src={service11} alt="About Us" className="about-image" />

            </div>
            <div className="services-content">
              <h3>
              We Also Accept Credit Card Payment
              </h3>
              <p>
                Nullam molestie lacus sit amet velit fermentum feugiat. Mauris auctor eget nunc sit amet.
              </p>
              <a to="" className="read-more-link">
                Read More <i className="fal fa-long-arrow-right"></i>
              </a>
            </div>
          </div>
        </Grid>

        {/* Card 2 */}
        <Grid item xs={12} sm={6} md={4}>
          <div className="services-card">
            <div className="services-icon">
            <img src={service10} alt="About Us" className="about-image" />

            </div>
            <div className="services-content">
              <h3>
               Tv Set Available In Every Room
              </h3>
              <p>
                Nullam molestie lacus sit amet velit fermentum feugiat. Mauris auctor eget nunc sit amet.
              </p>
              <a to="" className="read-more-link">
                Read More <i className="fal fa-long-arrow-right"></i>
              </a>
            </div>
          </div>
        </Grid>

        {/* Card 3 */}
        <Grid item xs={12} sm={6} md={4}>
          <div className="services-card">
            <div className="services-icon">
            <img src={service9} alt="About Us" className="about-image" />

            </div>
            <div className="services-content">
              <h3>
             Ac And Non-Ac Room Available
              </h3>
              <p>
                Nullam molestie lacus sit amet velit fermentum feugiat. Mauris auctor eget nunc sit amet.
              </p>
              <a to="" className="read-more-link">
                Read More <i className="fal fa-long-arrow-right"></i>
              </a>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <div className="services-card">
            <div className="services-icon">
            <img src={service8} alt="About Us" className="about-image" />

            </div>
            <div className="services-content">
              <h3>
               Delux Room
              </h3>
              <p>
                Nullam molestie lacus sit amet velit fermentum feugiat. Mauris auctor eget nunc sit amet.
              </p>
              <a to="" className="read-more-link">
                Read More <i className="fal fa-long-arrow-right"></i>
              </a>
            </div>
          </div>
        </Grid>

        {/* Card 3 */}
        <Grid item xs={12} sm={6} md={4}>
          <div className="services-card">
            <div className="services-icon">
            <img src={service7} alt="About Us" className="about-image" />

            </div>
            <div className="services-content">
              <h3>
            Wifi Available
              </h3>
              <p>
                Nullam molestie lacus sit amet velit fermentum feugiat. Mauris auctor eget nunc sit amet.
              </p>
              <a to="" className="read-more-link">
                Read More <i className="fal fa-long-arrow-right"></i>
              </a>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ServiceSection;
