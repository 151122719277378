import React from "react";
import TopHeader from "./TopHeader"; // Import TopHeader component
import MainHeader from "./MainHeader"; // Import MainHeader component
import Footer from "./Footer"; // Import MainHeader component
import { Box } from "@mui/material";
const PageLayout = ({ children }) => {
  return (
    <Box>
      {/* Top Header */}
      <TopHeader />

      {/* Main Header */}
      <MainHeader />

      {/* Main Content */}
      {children}
      <Footer/>
    </Box>
  );
};

export default PageLayout;
