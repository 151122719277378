import React from 'react'
import PageLayout from '../../Components/Layout/PageLayout'
import RoomCard from './RoomCard'
import BreadcrumbSectionOurRooms from './BreadcrumbSectionOurRooms'
import LuxuryHotel from '../Home/LuxuryHotel'
import BookRoom from './BookRoom'
import TakeTour from '../Home/TakeTour'

const Our_Rooms = () => {
  return (
    <div>
      <PageLayout>
        <BreadcrumbSectionOurRooms/>
       <RoomCard/>
       <LuxuryHotel/>
       <BookRoom/>
       <TakeTour/>
    
      </PageLayout>
    </div>
  )
}

export default Our_Rooms
