import React from "react";
import ReactDOM from "react-dom";
import "./index.css"; // Your global CSS (if any)
import App from "./App";

// Import Slick carousel styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@fortawesome/fontawesome-free/css/all.css";
import { Grid } from "swiper/modules";
import { dividerClasses } from "@mui/material";
// src/hooks/index.js
export const useClearableField = () => {}; // Just a placeholder if needed
export const useSplitFieldProps = () => {}; // Just a placeholder if needed



ReactDOM.render(
  <React.StrictMode>
    <App />
    {/* <Test /> */}
  </React.StrictMode>,
  document.getElementById("root")
);
