import React from "react";
import PageLayout from "../../Components/Layout/PageLayout";
import HomeSlider from "./HomeSlider"
import Booking from "./Booking";
import AboutSection from "../AboutUs/AboutSection";
import HotelSection from "./HotelSection";
import ServiceSection from "../Facilities/ServiceSection";
import RoomsSlider from "./RoomsSlider";
import RoomSection from "./RoomSection";
import LuxuryHotel from "./LuxuryHotel";
import BookRoom from "../Room/BookRoom";
import TakeTour from "./TakeTour";
import PricingSection from "./PricingSection";
import TestimonialSlider from "./TestimonialSlider";
import BlogSection from "../Blog/Blogsection";
import Partner from "./Partner";
import OurTeam from "./OurTeam";
import KolhapurTourismSection from "../KolhapurTourism/KolhapurTourismSection";
import ImageDisplay from "./ImageDisplay";
// import Testimonial from "./Testimonial";
// import "./HomeSlider.css
import mapimage from "../../Assets/Images/map.jpg"
import InfoSection from "./Infosection";
import PicnicPoint from "./PicnicPoint";

const Home = () => {
  return (
    <PageLayout>
      <HomeSlider />
      <Booking />
      <AboutSection/>
 <HotelSection/>
     <ServiceSection/>
     {/* <RoomSection/> */}

     {/* <RoomsSlider/>
     <LuxuryHotel/>
     <PricingSection/> */}
{/* <TestimonialSlider/> */}
<OurTeam/>
     {/* <BookRoom/>
     <TakeTour/> */}
    
     {/* <Testimonial/> */}
     {/* <BlogSection/> */}
     {/* <KolhapurTourismSection/> */}
<PicnicPoint/>

     <ImageDisplay src={mapimage} width="100%" height="600px" />
<InfoSection/>

     {/* <Partner/> */}
    </PageLayout>
  );
};

export default Home;
