import React from "react";
import { Box, Container, Grid2, Typography, IconButton } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";

const TopHeader = () => {
  return (
    <Box bgcolor="#efbf04" py={1}>
      <Container>
        <Grid2 container alignItems="center" justifyContent="space-between">
          <Grid2
            item
            md={10}
            xs={12}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <Typography variant="body2" color="white">
              Mon - Fri: 9:00 - 19:00 / Closed on Weekends &nbsp; | &nbsp;
              <strong>+91 705 210-1786</strong>
            </Typography>
          </Grid2>

          {/* Social Media Section */}
          <Grid2
            item
            md={2}
            xs={12}
            sx={{
              textAlign: "right",
              display: { xs: "none", md: "block" },
              color: "white",
            }}
          >
            <IconButton
              to="/"
              title="Facebook"
              sx={{ color: "inherit" }}
            >
              <FacebookIcon fontSize="small" />
            </IconButton>
            <IconButton
              to="/"
              title="Instagram"
              sx={{ color: "inherit" }}
            >
              <InstagramIcon fontSize="small" />
            </IconButton>
            <IconButton
              to="/"
              title="Twitter"
              sx={{ color: "inherit" }}
            >
              <TwitterIcon fontSize="small" />
            </IconButton>
            <IconButton
              to="/"
              title="YouTube"
              sx={{ color: "inherit" }}
            >
              <YouTubeIcon fontSize="small" />
            </IconButton>
          </Grid2>
        </Grid2>
      </Container>
    </Box>
  );
};

export default TopHeader;
