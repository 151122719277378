import React from "react";
import Slider from "react-slick";
import slider1 from "../../Assets/Images/4.jpg";
import slider2 from "../../Assets/Images/1.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./HomeSlider.css";
import {Link} from 'react-router-dom';

// import  "./Booking.css";

const PrevButton = ({ onClick }) => (
  <button
    type="button"
    data-role="none"
    className="slick-arrow slick-prev"
    onClick={onClick}
  >
    Previous
  </button>
);

const NextButton = ({ onClick }) => (
  <button
    type="button"
    data-role="none"
    className="slick-arrow slick-next"
    onClick={onClick}
    display="block"
  >
    Next
  </button>
);

const HomeSlider = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevButton />,
    nextArrow: <NextButton />,
  };

  return (
    <section
      id="home"
      className="slider-area fix p-relative slider-active"
      style={{ width: "100%", height: "100vh" }}
    >
      <Slider {...settings}>
        <div
          className="single-slider slider-bg d-flex align-items-center"
          style={{
            backgroundImage: `url(${slider1})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-7 col-md-7">
                <div className="slider-content s-slider-content mt-80 text-center">
                  <h2>Enjoy A Luxury Experience</h2>
                  <p>
                    Donec vitae libero non enim placerat eleifend aliquam erat
                    volutpat. Curabitur diam ex, dapibus purus sapien, cursus
                    sed nisl tristique, commodo gravida lectus non.
                  </p>
                  <div className="slider-btn mt-30 mb-105">
                    <Link
                      className="btn ss-btn active mr-15"
                      to="/contact"
                    >
                      Discover More
                    </Link>
                    <Link className="video-i popup-video" to="/">
                      <i className="fas fa-play"></i> Intro Video
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="single-slider1 slider-bg d-flex align-items-center"
          style={{
            backgroundImage: `url(${slider2})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="container">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-7 col-md-7">
                <div className="slider-content s-slider-content mt-80 text-center">
                  <h2>Enjoy A Luxury Experience</h2>
                  <p>
                    Donec vitae libero non enim placerat eleifend aliquam erat
                    volutpat. Curabitur diam ex, dapibus purus sapien, cursus
                    sed nisl tristique, commodo gravida lectus non.
                  </p>
                  <div className="slider-btn mt-30 mb-105">
                    <Link
                      className="btn ss-btn active mr-15"
                      to="/contact"
                    >
                      Discover More
                    </Link>
                    <Link className="video-i popup-video" to="/relax">
                      <i className="fas fa-play"></i> Intro Video
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
      {/* <Booking /> */}
     {/* <AboutSection/> */}
     {/* <HotelSection/> */}
     {/* <ServiceSection/> */}
  
    </section>
  );
};

export default HomeSlider;
