import React from 'react';



import './About.css';

import BreadcrumbSection from './BreadcrumbSectionAbout';
import AboutSection from './AboutSection';
import PageLayout from '../../Components/Layout/PageLayout';
import LuxuryHotel from '../Home/LuxuryHotel';
import BlogSection from '../Blog/Blogsection';
import AboutUsPart from './AboutUsPart';
import Newsletter from './Newsletter';
import OurTeam from '../Home/OurTeam';


const About = () => {
  return (
    <div>
      {/* Display headers */}
      <PageLayout>
      

      {/* Breadcrumb section */}
      <BreadcrumbSection />

      {/* About Section */}
          <AboutSection/> 
        <OurTeam/>
          {/* <AboutUsPart/> */}
           {/* <LuxuryHotel/>
           <BlogSection/> */}
           {/* <Newsletter/> */}
          </PageLayout>      
    </div>
  );
};

export default About;
